import logo from '@/assets/images/logo.png'
import './index.less'
import { GlobalContextProps, globalContext } from '@/layout'
import { useContext } from 'react'
import { useNavigate } from 'react-router-dom'

const Logo = () => {
  const { isCollapse }: GlobalContextProps = useContext(globalContext)
  const nav = useNavigate()

  const title = window.location.host !== 'crush-admin-staging.makefriends-inc.com' ? 'Crush后台' : 'Crush 测试'

  return (
    <div className='logo-box' onClick={() => nav('/')}>
      <img src={logo} alt='logo' className='logo-img' />
      {!isCollapse ? (
        <h2 className='logo-text' style={{ color: '#fff' }}>
          {title}
        </h2>
      ) : null}
    </div>
  )
}

export default Logo
