import ReactDOM from 'react-dom/client'
import App from './App'
import * as Sentry from '@sentry/react'

const products = ['production', 'staging']
const VITE_ENV = import.meta.env.VITE_ENV
const isProd = products.includes(VITE_ENV)

// isProd &&
//   Sentry.init({
//     dsn: 'https://9948855e53f39417e380419e86deb8fa@sentry.makefriends-inc.com/16',
//     integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
//     environment: VITE_ENV,
//     // Performance Monitoring
//     tracesSampleRate: isProd ? 1 : 0.1, //  Capture 100% of the transactions
//     // https://docs.sentry.io/platforms/javascript/guides/react/configuration/options/#sample-rate
//     sampleRate: isProd ? 1 : 0.1,
//     // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
//     tracePropagationTargets: [/^https?:\/\/api\.crushonfeature\.com\/api/],
//     // Session Replay
//     replaysSessionSampleRate: 0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
//     replaysOnErrorSampleRate: 1.0 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
//   })

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(<App />)
